* {
    font-family: 'Montserrat', sans-serif;
    color: #333;
}

.form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.campo-preenchido {
    border-color: var(--bs-green);
    border-bottom: 2px;
}

a.nav-link {
    color: #565656;
}

footer,
header {
    display: none;
}

.produtos button,
.produtos input,
.produtos optgroup,
.produtos select,
.produtos textarea {
    width: 100% !important;
}

.produtos small {
    display: none;
}

.dados_bancarios_li p {
    margin-bottom: 0;
}

@media print {


    .alert.alert-success.p-2 {
        display: none;
    }

    .card-footer.d-flex {
        display: none !important;
    }

    .card-header:first-child {
        display: none;
    }

    .card.my-3.col-12 {
        border: 0;
    }

    body {
        background: #fff !important;
    }

    footer,
    header {
        display: block;
    }

    .brasfirst {
        display: none !important;
    }

    .input-group.px-5.pb-2 {
        display: none;
    }

    ul.list-group.mx-5.pb-5.border-0 {
        margin: 0 !important;
        border: 0;
    }

    .card.mt-5.mb-3.col-12 {
        border: 0;
    }

    ul.list-group.mx-5.pb-5.border-0 {
        padding: 0 !important;
    }







}


.buttonCloseList {
    border: 0;
    font-weight: 600;
    border-radius: 4px;
    color: #fff;
    background: #6c757d;
}